import Button from './components/Button/Button'
import Header from './components/Header/Header'
import s from './App.module.scss'
import { ImgWebp } from './helpers/ImgWebp'
import SectionNum from './components/SectionNum/SectionNum'
import { useMediaQuery } from 'usehooks-ts'
// import EllipsisText from 'react-ellipsis-text'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import Ellipsis from 'ellipsis.js'
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from 'react-parallax-mouse'
import { useEffect, useState } from 'react'

// stroke text imports
import mainStrokeText from './assets/img/strokeText/main/text.png'
import mainStrokeText2x from './assets/img/strokeText/main/text@2x.png'
import mainStrokeTextWebp from './assets/img/strokeText/main/text.webp'
import video1StrokeText from './assets/img/strokeText/video/show.png'
import video1StrokeText2x from './assets/img/strokeText/video/show@2x.png'
import video1StrokeTextWebp from './assets/img/strokeText/video/show.webp'
import video2StrokeText from './assets/img/strokeText/video/reel.png'
import video2StrokeText2x from './assets/img/strokeText/video/reel@2x.png'
import video2StrokeTextWebp from './assets/img/strokeText/video/reel.webp'
import ClientsStrokeText from './assets/img/strokeText/clients/text.png'
import ClientsStrokeText2x from './assets/img/strokeText/clients/text@2x.png'
import ClientsStrokeTextWebp from './assets/img/strokeText/clients/text.webp'
import ClientsStrokeTextMobile from './assets/img/strokeText/clients/text-mobile.png'
import ClientsStrokeTextMobile2x from './assets/img/strokeText/clients/text-mobile@2x.png'
import ClientsStrokeTextMobileWebp from './assets/img/strokeText/clients/text-mobile.webp'
import BrandsStrokeText from './assets/img/strokeText/brands/text.png'
import BrandsStrokeText2x from './assets/img/strokeText/brands/text@2x.png'
import BrandsStrokeTextWebp from './assets/img/strokeText/brands/text.webp'
import BrandsStrokeTextMobile from './assets/img/strokeText/brands/text-mobile.png'
import BrandsStrokeTextMobile2x from './assets/img/strokeText/brands/text-mobile@2x.png'
import BrandsStrokeTextMobileWebp from './assets/img/strokeText/brands/text-mobile.webp'
import WorkflowStrokeText from './assets/img/strokeText/workflow/text.png'
import WorkflowStrokeText2x from './assets/img/strokeText/workflow/text@2x.png'
import WorkflowStrokeTextWebp from './assets/img/strokeText/workflow/text.webp'
import WorkflowStrokeTextMobile from './assets/img/strokeText/workflow/text-mobile.png'
import WorkflowStrokeTextMobile2x from './assets/img/strokeText/workflow/text-mobile@2x.png'
import WorkflowStrokeTextMobileWebp from './assets/img/strokeText/workflow/text-mobile.webp'
import AdvantagesStrokeText from './assets/img/strokeText/advantages/text.png'
import AdvantagesStrokeText2x from './assets/img/strokeText/advantages/text@2x.png'
import AdvantagesStrokeTextWebp from './assets/img/strokeText/advantages/text.webp'
import AdvantagesStrokeTextMobile from './assets/img/strokeText/advantages/text-mobile.png'
import AdvantagesStrokeTextMobile2x from './assets/img/strokeText/advantages/text-mobile@2x.png'
import AdvantagesStrokeTextMobileWebp from './assets/img/strokeText/advantages/text-mobile.webp'
import FormStrokeText from './assets/img/strokeText/form/text.png'
import FormStrokeText2x from './assets/img/strokeText/form/text@2x.png'
import FormStrokeTextWebp from './assets/img/strokeText/form/text.webp'
import FormStrokeTextMobile from './assets/img/strokeText/form/text-mobile.png'
import FormStrokeTextMobile2x from './assets/img/strokeText/form/text-mobile@2x.png'
import FormStrokeTextMobileWebp from './assets/img/strokeText/form/text-mobile.webp'
import MediaStrokeText from './assets/img/strokeText/media/text.png'
import MediaStrokeText2x from './assets/img/strokeText/media/text@2x.png'
import MediaStrokeTextWebp from './assets/img/strokeText/media/text.webp'
import MediatrokeTextMobile from './assets/img/strokeText/media/text-mobile.png'
import MediatrokeTextMobile2x from './assets/img/strokeText/media/text-mobile@2x.png'
import MediatrokeTextMobileWebp from './assets/img/strokeText/media/text-mobile.webp'
import ContactsStrokeText from './assets/img/strokeText/contacts/text.png'
import ContactsStrokeText2x from './assets/img/strokeText/contacts/text@2x.png'
import ContactsStrokeTextWebp from './assets/img/strokeText/contacts/text.webp'
import ContactsStrokeTextMobile from './assets/img/strokeText/contacts/text-mobile.png'
import ContactsStrokeTextMobile2x from './assets/img/strokeText/contacts/text-mobile@2x.png'
import ContactsStrokeTextMobileWebp from './assets/img/strokeText/contacts/text-mobile.webp'

// video bg imports
import mainBgVideo from './assets/video/bg1.mp4'
import clientsBgVideo from './assets/video/bg3.mp4'
import workflowBgVideo from './assets/video/bg4.mp4'
import advantagesBgVideo from './assets/video/bg5_update9.mp4'
import brandsBgVideo from './assets/video/bg7.mp4'
import FormBgVideo from './assets/video/bg6.mp4'
import MediaBgVideo from './assets/video/bg8.mp4'
import ContactsBgVideo from './assets/video/bg9.mp4'

// icon imports
import CrossSvg from './assets/img/icons/CrossSvg'
import DiagonalSvg from './assets/img/icons/DiagonalSvg'
import VersionSvg from './assets/img/icons/VersionSvg'
import ArrowSvg from './assets/img/icons/ArrowSvg'
import StarSvg from './assets/img/workflow/star.svg'
import BrainSvg from './assets/img/workflow/brain.svg'
import DocumentSvg from './assets/img/workflow/document.svg'
import GearSvg from './assets/img/workflow/gears.svg'
import TargetSvg from './assets/img/workflow/target.svg'
import TgSvg from './assets/img/contacts/tg.svg'
import GitSvg from './assets/img/contacts/git.svg'
import CheclSvg from './assets/img/icons/check.svg'

// img imports
import PersonImg from './assets/img/main/card/person.png'
import PersonImg2x from './assets/img/main/card/person@2x.png'
import PersonImgWebp from './assets/img/main/card/person.webp'
import PersonBorder from './assets/img/main/card/border.png'
import PersonBorder2x from './assets/img/main/card/border@2x.png'
import PersonBorderWebp from './assets/img/main/card/border.webp'
import CardParallax from './assets/img/main/card/card.png'
import CardParallax2x from './assets/img/main/card/card@2x.png'
import CardParallaxWebp from './assets/img/main/card/card.webp'
import Phone from './assets/img/main/card/phone.png'
import Phone2x from './assets/img/main/card/phone@2x.png'
import PhoneWebp from './assets/img/main/card/phone.webp'
// import Preview from './assets/img/video/preview.jpg'
// import Preview2x from './assets/img/video/preview@2x.jpg'
// import PreviewWebp from './assets/img/video/preview.webp'
// import PlaySvg from './assets/img/icons/PlaySvg'
import ClientsLogo01Svg from './assets/img/clients/logo01.svg'
import ClientsLogo02Svg from './assets/img/clients/logo02.svg'
import ClientsLogo03Svg from './assets/img/clients/logo03.svg'
import ClientsLogo04Svg from './assets/img/clients/logo04.svg'
import ClientsLogo05Svg from './assets/img/clients/logo05.svg'
import ClientsLogo06Svg from './assets/img/clients/logo06.svg'
import Brand01Svg from './assets/img/brands/brand01.svg'
import Brand02Svg from './assets/img/brands/brand02.svg'
import Brand03Svg from './assets/img/brands/brand03.svg'
import Brand04Svg from './assets/img/brands/brand04.svg'
import LineSvg from './assets/img/workflow/line.svg'

function App() {
  return (
    <div className='wrapper'>
      <div className='content'>
        <Header />

        <MainSection />
        <VideoSection />
        <CliensSection />
        <WorkflowSection />
        <AdvantagesSection />
        <CallusSection />
        <BrandsSection />
        <MediaSection />
        <ContactsSection />
      </div>
    </div>
  )
}

const MainSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  const toSection = (sectionId) => {
    const section = document
      .querySelector(`#${sectionId}`)
      .getBoundingClientRect()

    window.scrollTo({
      top: section.top,
      behavior: 'smooth',
    })
  }

  if (isMobile) {
    return (
      <main className={s.main}>
        <div className='container'>
          <div className={s.mainBody}>
            <div className={s.mainInfo__title}>
              <span>it как </span>
              <span>в кино</span>
            </div>

            <div className={s.mainCard}>
              <div className={s.mainCard__img}>
                <div className={s.mainCard__imgBorder}>
                  <ImgWebp
                    src={PersonBorder}
                    src2x={PersonBorder2x}
                    srcWebp={PersonBorderWebp}
                  />
                </div>
                <ImgWebp
                  src={PersonImg}
                  src2x={PersonImg2x}
                  srcWebp={PersonImgWebp}
                />
              </div>
              <div className={s.mainCard__title}>Григорий Дядиченко</div>
              <div className={s.mainCard__bottom}>
                <div className={s.mainCard__bottomPos}>
                  технический <br /> продюссер
                </div>
                <Button
                  className={s.mainCard__bottomBtn}
                  href={process.env.REACT_APP_BLOG_LINK}
                  title={'читать блог'}
                />
              </div>
            </div>

            <Button
              onClick={() => toSection('callus')}
              title={'заказать проект'}
              className={s.mainInfo__btn}
            />
          </div>
        </div>
      </main>
    )
  } else {
    return (
      <main className={s.main}>
        <div className={s.videobg}>
          <video autoPlay muted loop src={mainBgVideo}></video>
          <span className={s._bottom}></span>
        </div>

        <div className='container'>
          <div className={s.mainBody}>
            <Button
              onClick={() => toSection('callus')}
              isToDown
              className={s.mainBody__downBtn}
            />

            <div className={s.mainIcons}>
              <div className={s.mainIcons__item}>
                <CrossSvg />
              </div>
              <div className={s.mainIcons__item}>
                <ArrowSvg />
              </div>
              <div className={s.mainIcons__item}>
                <VersionSvg />
              </div>
              <div className={s.mainIcons__item}>
                <SectionNum num={'01'} />
              </div>
            </div>

            <MouseParallaxContainer
              className={s.mainParallax}
              useWindowMouseEvents
              globalFactorX={0.2}
              globalFactorY={0.1}
            >
              <MouseParallaxChild
                factorX={0.6}
                factorY={0.2}
                className={s.mainCard}
              >
                {/* <div className={s.mainCard}> */}
                <div className={s.mainCard__img}>
                  <div className={s.mainCard__imgBorder}>
                    <ImgWebp
                      src={PersonBorder}
                      src2x={PersonBorder2x}
                      srcWebp={PersonBorderWebp}
                    />
                  </div>
                  <ImgWebp
                    src={PersonImg}
                    src2x={PersonImg2x}
                    srcWebp={PersonImgWebp}
                  />
                </div>
                <div className={s.mainCard__title}>Григорий Дядиченко</div>
                <div className={s.mainCard__bottom}>
                  <div className={s.mainCard__bottomPos}>
                    технический <br /> продюссер
                  </div>
                  <Button
                    className={s.mainCard__bottomBtn}
                    title={'читать блог'}
                    href={process.env.REACT_APP_BLOG_LINK}
                  />
                </div>
                {/* </div> */}
              </MouseParallaxChild>

              <MouseParallaxChild
                className={s.mainParallax__item}
                factorX={0.5}
                factorY={0.2}
              >
                <div className={s.mainCard__parallaxItem}>
                  <ImgWebp
                    src={CardParallax}
                    src2x={CardParallax2x}
                    srcWebp={CardParallaxWebp}
                  />
                </div>
              </MouseParallaxChild>
              <MouseParallaxChild
                className={s.mainParallax__item}
                factorX={0.4}
                factorY={0.2}
              >
                <div className={s.mainCard__parallaxItem}>
                  <ImgWebp
                    src={CardParallax}
                    src2x={CardParallax2x}
                    srcWebp={CardParallaxWebp}
                  />
                </div>
              </MouseParallaxChild>
              <MouseParallaxChild
                className={s.mainParallax__item}
                factorX={0.3}
                factorY={0.2}
              >
                <div className={s.mainCard__parallaxItem}>
                  <ImgWebp
                    src={CardParallax}
                    src2x={CardParallax2x}
                    srcWebp={CardParallaxWebp}
                  />
                </div>
              </MouseParallaxChild>
              <MouseParallaxChild
                className={s.mainParallax__item}
                factorX={0.2}
                factorY={0.2}
              >
                <div className={s.mainCard__parallaxItem}>
                  <ImgWebp
                    src={CardParallax}
                    src2x={CardParallax2x}
                    srcWebp={CardParallaxWebp}
                  />
                </div>
              </MouseParallaxChild>

              <MouseParallaxChild
                className={s.mainParallax__item}
                factorX={0}
                factorY={0}
              >
                <div className={s.mainCard__phone}>
                  <ImgWebp src={Phone} src2x={Phone2x} srcWebp={PhoneWebp} />
                </div>
              </MouseParallaxChild>
            </MouseParallaxContainer>

            <div className={s.mainInfo}>
              <div className={s.mainInfo__title}>
                <span>it как </span>
                <span>в кино</span>
              </div>
              <Button
                onClick={() => toSection('callus')}
                title={'заказать проект'}
                className={s.mainInfo__btn}
              />
            </div>
            <ImgWebp
              className={s.mainBody__text}
              src={mainStrokeText}
              src2x={mainStrokeText2x}
              srcWebp={mainStrokeTextWebp}
            />
          </div>
        </div>
      </main>
    )
  }
}

const VideoSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  return (
    <section className={s.video} id='showreel'>
      <div className='container'>
        <div className={s.videoBody}>
          <div className={s.videoBody__preview}>
            <iframe
              src={`${process.env.REACT_APP_VIDEO_LINK}?autoplay=1`}
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            ></iframe>
          </div>

          {/* <div className={s.videoBody__btn}>
            <PlaySvg />
          </div> */}

          <div className={s.videoBody__text}>
            <span className={s._top}>
              <ImgWebp
                src={video1StrokeText}
                src2x={video1StrokeText2x}
                srcWebp={video1StrokeTextWebp}
              />
            </span>
            <span className={s._bottom}>
              <ImgWebp
                src={video2StrokeText}
                src2x={video2StrokeText2x}
                srcWebp={video2StrokeTextWebp}
              />
            </span>
          </div>

          {isMobile ? '' : <SectionNum className={s.videoBody__num} num='02' />}
        </div>
      </div>
    </section>
  )
}

const CliensSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  if (isMobile) {
    return (
      <section className={s.clients} id='clients'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              клиенты
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={ClientsStrokeTextMobile}
                  src2x={ClientsStrokeTextMobile2x}
                  srcWebp={ClientsStrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>

          <div className={s.clientsBody}>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo01Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo02Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo03Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo04Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo05Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo06Svg} alt='' />
            </div>
          </div>
          <div className={s.clientsTop}>
            <div className={s.clientsTop__item}>
              <div className={s.clientsTop__itemValue}>6+</div>
              <div className={s.clientsTop__itemText}>
                Более 6 лет <br /> в продакшене
              </div>
            </div>
            <div className={s.clientsTop__item}>
              <div className={s.clientsTop__itemValue}>100+</div>
              <div className={s.clientsTop__itemText}>
                реализованых <br /> проектов
              </div>
            </div>
            <div className={s.clientsTop__item}>
              <div className={s.clientsTop__itemValue}>4+</div>
              <div className={s.clientsTop__itemText}>
                продуктов <br /> и брендов
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.clients} id='clients'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={clientsBgVideo}></video>
          <span className={s._bottom}></span>
          <span className={s._top}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              клиенты
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={ClientsStrokeText}
                  src2x={ClientsStrokeText2x}
                  srcWebp={ClientsStrokeTextWebp}
                />
              </div>
            </div>

            <div className={s.sectionTop__content}>
              <div className={s.clientsTop}>
                <div className={s.clientsTop__item}>
                  <div className={s.clientsTop__itemValue}>6+</div>
                  <div className={s.clientsTop__itemText}>
                    Более 6 лет <br /> в продакшене
                  </div>
                </div>
                <div className={s.clientsTop__item}>
                  <div className={s.clientsTop__itemValue}>100+</div>
                  <div className={s.clientsTop__itemText}>
                    реализованых <br /> проектов
                  </div>
                </div>
                <div className={s.clientsTop__item}>
                  <div className={s.clientsTop__itemValue}>4+</div>
                  <div className={s.clientsTop__itemText}>
                    продуктов <br /> и брендов
                  </div>
                </div>
              </div>

              <div className={s.sectionTop__contentLine}></div>
            </div>
          </div>

          <div className={s.clientsBody}>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo01Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo02Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo03Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo04Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo05Svg} alt='' />
            </div>
            <div className={s.clientsBody__item}>
              <span></span>
              <span></span>
              <img src={ClientsLogo06Svg} alt='' />
            </div>

            <div className={s.clientsIcons}>
              <div className={s.clientsIcons__item}>
                <ArrowSvg />
              </div>
              <div className={s.clientsIcons__item}>
                <CrossSvg />
              </div>
              <SectionNum className={s.clientsIcons__item} num='03' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const WorkflowSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  if (isMobile) {
    return (
      <section className={s.workflow} id='workflow'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              принцип <br /> работы
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={WorkflowStrokeTextMobile}
                  src2x={WorkflowStrokeTextMobile2x}
                  srcWebp={WorkflowStrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.workflowBody}>
            <div className={s.workflowBody__item}>
              <div className={s.workflowBody__itemTitle}>старт</div>
              <div className={s.workflowBody__itemIcon}>
                <img src={StarSvg} alt='' />
              </div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.workflowBody__itemTitle}>препродакшн</div>
              <div className={s.workflowBody__itemIcon}>
                <img src={BrainSvg} alt='' />
              </div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.workflowBody__itemTitle}>согласование</div>
              <div className={s.workflowBody__itemIcon}>
                <img src={DocumentSvg} alt='' />
              </div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.workflowBody__itemTitle}>продакшн</div>
              <div className={s.workflowBody__itemIcon}>
                <img src={GearSvg} alt='' />
              </div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.workflowBody__itemTitle}>результат</div>
              <div className={s.workflowBody__itemIcon}>
                <img src={TargetSvg} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.workflow} id='workflow'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={workflowBgVideo}></video>
          <span className={s._bottom}></span>
          <span className={s._top}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              принцип работы
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={WorkflowStrokeText}
                  src2x={WorkflowStrokeText2x}
                  srcWebp={WorkflowStrokeTextWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.workflowBody}>
            <div className={s.workflowBody__item}>
              <div className={s.line}>
                <div className={s.lineIcon}>
                  <img src={StarSvg} alt='' />
                </div>
                <div className={s.lineCircle}></div>
              </div>
              <div className={s.name}>старт</div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.line}>
                <div className={s.lineIcon}>
                  <img src={BrainSvg} alt='' />

                  <div className={s.radial}>
                    <div className={s.radialItem}>
                      <div className={s.radialItem__tooltip}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Сбор бизнес требоаваний
                        </div>
                      </div>
                      <div className={s.radialItem__tooltip}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Подбор команды
                        </div>
                      </div>
                    </div>
                    <div className={`${s.radialItem} ${s._hidden}`}></div>
                    <div className={s.radialItem}>
                      <div className={s.radialItem__tooltip}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Составление ТЗ
                        </div>
                      </div>
                      <div className={s.radialItem__tooltip}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Формирование бюджета
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={s.lineCircle}></div>
              </div>
              <div className={s.name}>препродакшн</div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.line}>
                <div className={s.lineIcon}>
                  <img src={DocumentSvg} alt='' />
                </div>
                <div className={s.lineCircle}></div>
              </div>
              <div className={s.name}>Согласование</div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.line}>
                <div className={s.lineIcon}>
                  <img src={GearSvg} alt='' />

                  <div className={s.radial}>
                    <div className={s.radialItem}>
                      <div className={s.radialItem__tooltip}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Разработка
                        </div>
                      </div>
                    </div>
                    <div className={`${s.radialItem}`}>
                      <div className={`${s.radialItem__tooltip} ${s._bottom}`}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Дизайн
                        </div>
                      </div>
                    </div>
                    <div className={s.radialItem}>
                      <div className={s.radialItem__tooltip}>
                        <span></span>
                        <div className={s.radialItem__tooltipContent}>
                          Публикация
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={s.lineCircle}></div>
              </div>
              <div className={s.name}>продакшн</div>
            </div>
            <div className={s.workflowBody__item}>
              <div className={s.line}>
                <div className={s.lineIcon}>
                  <img src={TargetSvg} alt='' />
                </div>
                <div className={s.lineCircle}></div>
              </div>
              <div className={s.name}>РЕзультат</div>
            </div>

            <div className={s.workflowBody__line}>
              <img src={LineSvg} alt='' />
            </div>

            <div className={s.workflowIcons}>
              <div className={s.workflowIcons__item}>
                <ArrowSvg />
              </div>
              <SectionNum className={s.workflowIcons__item} num='04' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const AdvantagesSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  if (isMobile) {
    return (
      <section className={s.advantages} id='advantages'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              ПРЕИМУЩЕСТВА
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={AdvantagesStrokeTextMobile}
                  src2x={AdvantagesStrokeTextMobile2x}
                  srcWebp={AdvantagesStrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.advantagesBody}>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы не платите
                <br />
                за мой офис
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  Вы не платите за мой офис, за простой штата и за многое
                  другое, что закладывается в цену проекта студиями.
                </p>
                <p>
                  В отличии от работы со студией команда собирается под проект и
                  удалённо. Вы не оплачиваете ничего лишнего и платите только за
                  фактические проделанную работу.
                </p>
                <p>
                  Цена формируется на этапе технического задания и является
                  финальной в рамках проекта.
                </p>
              </div>
            </div>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы не занимаетесь <br /> микроменеджментом
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  В отличии от работы с фрилансерами вы не занимаетесь контролем
                  каждого отдельного исполнителя. Вы ставите общую задачу и
                  получаете решение вашей задачи. Вы не проверяете сделал ли
                  каждый человек свои задачи в срок. Вы не рискуете с тем, что
                  человек пропадёт или не сделает работу.
                </p>
                <p>
                  Эту ответственность беру на себя я. И если конкретный
                  исполнитель пропал или не сделал работу, то новый исполнитель
                  будет подобран максимально оперативно и в тот же бюджет. Таким
                  образом, что чаще всего вы даже этого не заметите.
                </p>
              </div>
            </div>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы получаете
                <br />
                качественный результат
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  Мы работаем вдвоём с арт-директором. Я в разработке больше 8
                  лет, он в дизайне более 15 лет. Совместно мы реализовали более
                  60 проектов с бюджетами от 100 тысяч рублей до 30 миллионов.
                </p>
                <p>
                  Контроль проекта осуществляется двумя профессионалами в своём
                  деле, а не менеджером и специалистом, которого сейчас смогла
                  выделить студия. Мы поможем вам придумать концепцию, которая
                  решит вашу задачу.
                </p>
              </div>
            </div>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы получаете лучших
                <br />
                специалистов рынка
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  В отличии от студий, у которых сформированная экспертиза и
                  штат, мы подбираем специалистов под конкретный проект и
                  бюджет, что даёт нам возможность оптимально реализовывать
                  поставленные задачи.
                </p>
                <p>
                  У студии может не быть в штате специалистов по VFX, рендеру,
                  сложным технологиям и математическим решениям. Мы же можем
                  привлечь специалистов, которые нужны именно вам.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.advantages} id='advantages'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={advantagesBgVideo}></video>
          <span className={s._top}></span>
          <span className={s._bottom}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              ПРЕИМУЩЕСТВА
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={AdvantagesStrokeText}
                  src2x={AdvantagesStrokeText2x}
                  srcWebp={AdvantagesStrokeTextWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.advantagesBody}>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы не платите
                <br />
                за мой офис
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  Вы не платите за мой офис, за простой штата и за многое
                  другое, что закладывается в цену проекта студиями.
                </p>
                <p>
                  В отличии от работы со студией команда собирается под проект и
                  удалённо. Вы не оплачиваете ничего лишнего и платите только за
                  фактические проделанную работу.
                </p>
                <p>
                  Цена формируется на этапе технического задания и является
                  финальной в рамках проекта.
                </p>
              </div>
            </div>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы не занимаетесь <br /> микроменеджментом
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  В отличии от работы с фрилансерами вы не занимаетесь контролем
                  каждого отдельного исполнителя. Вы ставите общую задачу и
                  получаете решение вашей задачи. Вы не проверяете сделал ли
                  каждый человек свои задачи в срок. Вы не рискуете с тем, что
                  человек пропадёт или не сделает работу.
                </p>
                <p>
                  Эту ответственность беру на себя я. И если конкретный
                  исполнитель пропал или не сделал работу, то новый исполнитель
                  будет подобран максимально оперативно и в тот же бюджет. Таким
                  образом, что чаще всего вы даже этого не заметите.
                </p>
              </div>
            </div>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы получаете
                <br />
                качественный результат
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  Мы работаем вдвоём с арт-директором. Я в разработке больше 8
                  лет, он в дизайне более 15 лет. Совместно мы реализовали более
                  60 проектов с бюджетами от 100 тысяч рублей до 30 миллионов.
                </p>
                <p>
                  Контроль проекта осуществляется двумя профессионалами в своём
                  деле, а не менеджером и специалистом, которого сейчас смогла
                  выделить студия. Мы поможем вам придумать концепцию, которая
                  решит вашу задачу.
                </p>
              </div>
            </div>
            <div className={s.advantagesBody__item}>
              <div className={s.advantagesBody__itemIco}>
                <img src={StarSvg} alt='' />
              </div>
              <div className={s.advantagesBody__itemTitle}>
                Вы получаете лучших
                <br />
                специалистов рынка
              </div>
              <div className={s.advantagesBody__itemDesc}>
                <p>
                  В отличии от студий, у которых сформированная экспертиза и
                  штат, мы подбираем специалистов под конкретный проект и
                  бюджет, что даёт нам возможность оптимально реализовывать
                  поставленные задачи.
                </p>
                <p>
                  У студии может не быть в штате специалистов по VFX, рендеру,
                  сложным технологиям и математическим решениям. Мы же можем
                  привлечь специалистов, которые нужны именно вам.
                </p>
              </div>
            </div>

            <div className={s.advantagesBody__icons}>
              <dir className={s.advantagesBody__iconsItem}>
                <ArrowSvg />
              </dir>
              <dir className={s.advantagesBody__iconsItem}>
                <CrossSvg />
              </dir>
              <dir className={s.advantagesBody__iconsItem}>
                <SectionNum num={'05'} />
              </dir>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const CallusSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')
  const [sendSuccess, setSendSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleEmailValidation = (email) => {
    console.log('ValidateEmail was called with', email)

    const isValid = isValidEmail(email)

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid)
    if (validityChanged) {
      console.log('Fire tracker with', isValid ? 'Valid' : 'Invalid')
    }

    return isValid
  }

  const validateForm = (data) => {
    console.log(data)
    // e.preventDefault()
    let formData = new FormData(document.querySelector('.callus-form'))

    let xhr = new XMLHttpRequest()

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        setSendSuccess(true)

        setTimeout(() => {
          setSendSuccess(false)
        }, 3000)
        if (xhr.status === 200) {
          console.log('Mail send')
        }
      }
    }

    xhr.open('POST', 'mail.php', true)
    xhr.send(formData)
  }

  if (isMobile) {
    return (
      <section className={s.callus} id='callus'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              закажите <br /> проект
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={FormStrokeTextMobile}
                  src2x={FormStrokeTextMobile2x}
                  srcWebp={FormStrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.callusBody}>
            <form
              action='#'
              onSubmit={handleSubmit(validateForm)}
              className={`${s.form} callus-form`}
            >
              <input
                {...register('name', {
                  required: true,
                  minLength: 3,
                })}
                placeholder='Как к вам обращаться*'
                type='text'
                className={s.form__item}
              />
              {errors.name && <span className={s._err}>Введите ваше имя</span>}
              <input
                {...register('email', {
                  required: true,
                  minLength: 3,
                  validate: handleEmailValidation,
                })}
                name='email'
                input-email
                placeholder='Почта*'
                type='text'
                className={s.form__item}
              />
              {errors.email && (
                <span className={s._err}>Введите свой email</span>
              )}
              <input
                name='company'
                input-email
                placeholder='Компания'
                type='text'
                className={s.form__item}
              />
              <textarea
                name=''
                placeholder='описание задачи'
                className={`${s.form__item} ${s._textarea}`}
              ></textarea>
              <div className={s.formBottom}>
                <Button title={'отправить'} className={s.form__btn} />
                <div className={s.formBottom__text}>
                  Отправляя эту форму, вы соглашаетесь <br />
                  <a href='#'>с политикой обработки персональных данных.</a>
                </div>
              </div>
              <div
                className={`${s.formSuccess} ${sendSuccess ? s.active : ''}`}
              >
                <div className={s.formSuccess__logo}>
                  <span></span>
                  <span></span>
                  <img src={CheclSvg} alt='' />
                </div>
                <div className={s.formSuccess__text}>
                  Сообщение успешно отправленно
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.callus} id='callus'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={FormBgVideo}></video>
          <span className={s._top}></span>
          <span className={s._bottom}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              закажите проект
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={FormStrokeText}
                  src2x={FormStrokeText2x}
                  srcWebp={FormStrokeTextWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.callusBody}>
            <form
              action='#'
              onSubmit={handleSubmit(validateForm)}
              className={`${s.form} callus-form`}
            >
              <input
                {...register('name', {
                  required: true,
                  minLength: 3,
                })}
                placeholder='Как к вам обращаться*'
                type='text'
                className={s.form__item}
              />
              {errors.name && <span className={s._err}>Введите ваше имя</span>}
              {/* <InputMask mask='+79999999999'>
                {(inputProps) => (
                  <input
                    placeholder='номер телефона'
                    className={s.form__item}
                    type='tel'
                    disableUnderline
                  />
                )}
              </InputMask>
              {errors.phone && (
                <span className={s._err}>Введите номер телефона</span>
              )} */}
              <input
                {...register('email', {
                  required: true,
                  minLength: 3,
                  validate: handleEmailValidation,
                })}
                name='email'
                input-email
                placeholder='Почта*'
                type='text'
                className={s.form__item}
              />
              {errors.email && (
                <span className={s._err}>Введите свой email</span>
              )}
              <input
                name='company'
                input-email
                placeholder='Компания'
                type='text'
                className={s.form__item}
              />
              <textarea
                name=''
                placeholder='описание задачи'
                className={`${s.form__item} ${s._textarea}`}
              ></textarea>
              <div className={s.formBottom}>
                <Button title={'отправить'} className={s.form__btn} />
                <div className={s.formBottom__text}>
                  Отправляя эту форму, вы соглашаетесь <br />
                  <a href='#'>с политикой обработки персональных данных.</a>
                </div>
              </div>

              <div
                className={`${s.formSuccess} ${sendSuccess ? s.active : ''}`}
              >
                <div className={s.formSuccess__logo}>
                  <span></span>
                  <span></span>
                  <img src={CheclSvg} alt='' />
                </div>
                <div className={s.formSuccess__text}>
                  Сообщение успешно отправленно
                </div>
              </div>
            </form>
            <div className={s.callusBody__icons}>
              <div className={s.callusBody__iconsItem}>
                <ArrowSvg />
              </div>
              <div className={s.callusBody__iconsItem}>
                <SectionNum num='05' />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const BrandsSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  if (isMobile) {
    return (
      <section className={s.brands} id='brands'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              бренды
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={BrandsStrokeTextMobile}
                  src2x={BrandsStrokeTextMobile2x}
                  srcWebp={BrandsStrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.brandsTop__text}>
            За время 6-ти летней работы сформировалось несколько продуктов.
            Можно ознакомиться с моей экспертизой через эти бренды.
          </div>
          <div className={s.brandsBody}>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand01Svg} alt='' />
            </a>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand02Svg} alt='' />
            </a>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand03Svg} alt='' />
            </a>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand04Svg} alt='' />
            </a>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.brands} id='brands'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={brandsBgVideo}></video>
          <span className={s._bottom}></span>
          <span className={s._top}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              бренды
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={BrandsStrokeText}
                  src2x={BrandsStrokeText2x}
                  srcWebp={BrandsStrokeTextWebp}
                />
              </div>
            </div>

            <div className={s.sectionTop__content}>
              <div className={s.brandsTop__text}>
                За время 6-ти летней работы сформировалось несколько продуктов.
                Можно ознакомиться с моей экспертизой через эти бренды.
              </div>

              <div className={s.sectionTop__contentLine}></div>
            </div>
          </div>

          <div className={s.brandsBody}>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand01Svg} alt='' />
            </a>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand02Svg} alt='' />
            </a>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand03Svg} alt='' />
            </a>
            <a href='' className={s.brandsBody__item}>
              <span></span>
              <span></span>
              <img src={Brand04Svg} alt='' />
            </a>

            <div className={s.clientsIcons}>
              <div className={s.clientsIcons__item}>
                <CrossSvg />
              </div>
              <div></div>
              <SectionNum className={s.clientsIcons__item} num='07' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

// const articleTitle1 = 'Почему тяжело писать <br /> про хороший код?'
const articleTitle1 = process.env.REACT_APP_ARTICLE_TITLE_01
const articleTitle2 = process.env.REACT_APP_ARTICLE_TITLE_02
const articleTitle3 = process.env.REACT_APP_ARTICLE_TITLE_03
const articleText1 = process.env.REACT_APP_ARTICLE_TEXT_01
const articleText2 = process.env.REACT_APP_ARTICLE_TEXT_02
const articleText3 = process.env.REACT_APP_ARTICLE_TEXT_03

const MediaSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  useEffect(() => {
    Ellipsis({
      lines: 5,
    })
  })

  if (isMobile) {
    return (
      <section className={s.media} id='media'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              выступления
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={MediatrokeTextMobile}
                  src2x={MediatrokeTextMobile2x}
                  srcWebp={MediatrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>

          <div className={s.mediaBody}>
            <div className={s.mediaBody__row}>
              <div className={s.mediaBody__item}>
                <iframe
                  allowfullscreen=''
                  loading='lazy'
                  src={process.env.REACT_APP_MEDIA_VIDEO_01}
                ></iframe>
              </div>
              <div className={s.mediaBody__item}>
                <iframe
                  allowfullscreen=''
                  loading='lazy'
                  src={process.env.REACT_APP_MEDIA_VIDEO_02}
                ></iframe>
              </div>
              <div className={s.mediaBody__item}>
                <iframe
                  allowfullscreen=''
                  loading='lazy'
                  src={process.env.REACT_APP_MEDIA_VIDEO_03}
                ></iframe>
              </div>
            </div>

            <div className={`${s.sectionTop} ${s.mediaArticles__title}`}>
              <div className={s.sectionTop__title}>
                Статьи
                <div className={s.sectionTop__titleBg}>
                  <ImgWebp
                    src={MediatrokeTextMobile}
                    src2x={MediatrokeTextMobile2x}
                    srcWebp={MediatrokeTextMobileWebp}
                  />
                </div>
              </div>
            </div>
            <div className={s.mediaArticles}>
              <a href='' target='_blank' className={s.mediaArticles__item}>
                <div className={s.mediaArticles__itemImg}>
                  <img
                    src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_01}
                    alt=''
                  />
                </div>
                <div className={s.mediaArticles__itemDesc}>
                  <div
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: articleTitle1 }}
                  ></div>
                  <div className={`${s.text} clamp`}>{articleText1}</div>
                </div>
              </a>
              <a href='' target='_blank' className={s.mediaArticles__item}>
                <div className={s.mediaArticles__itemImg}>
                  <img
                    src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_02}
                    alt=''
                  />
                </div>
                <div className={s.mediaArticles__itemDesc}>
                  <div
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: articleTitle2 }}
                  ></div>
                  <div className={`${s.text} clamp`}>{articleText2}</div>
                </div>
              </a>
              <a href='' target='_blank' className={s.mediaArticles__item}>
                <div className={s.mediaArticles__itemImg}>
                  <img
                    src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_03}
                    alt=''
                  />
                </div>
                <div className={s.mediaArticles__itemDesc}>
                  <div
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: articleTitle3 }}
                  ></div>
                  <div className={`${s.text} clamp`}>{articleText3}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.media} id='media'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={MediaBgVideo}></video>
          <span className={s._bottom}></span>
          <span className={s._top}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              выступления
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={MediaStrokeText}
                  src2x={MediaStrokeText2x}
                  srcWebp={MediaStrokeTextWebp}
                />
              </div>
            </div>
          </div>
          <div className={s.mediaBody}>
            <div className={s.mediaBody__row}>
              <div className={s.mediaBody__item}>
                <iframe
                  allowfullscreen=''
                  loading='lazy'
                  src={process.env.REACT_APP_MEDIA_VIDEO_01}
                ></iframe>
              </div>
              <div className={s.mediaBody__item}>
                <iframe
                  allowfullscreen=''
                  loading='lazy'
                  src={process.env.REACT_APP_MEDIA_VIDEO_02}
                ></iframe>
              </div>
              <div className={s.mediaBody__item}>
                <iframe
                  allowfullscreen=''
                  loading='lazy'
                  src={process.env.REACT_APP_MEDIA_VIDEO_03}
                ></iframe>
              </div>
            </div>

            <div className={s.mediaArticles__title}>статьи</div>
            <div className={s.mediaArticles}>
              <a
                href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_01}
                target='_blank'
                className={s.mediaArticles__item}
              >
                <div className={s.mediaArticles__itemImg}>
                  <img
                    src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_01}
                    alt=''
                  />
                </div>
                <div className={s.mediaArticles__itemDesc}>
                  <div
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: articleTitle1 }}
                  ></div>
                  <div className={`${s.text} clamp`}>{articleText1}</div>
                </div>
              </a>
              <a
                href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_02}
                target='_blank'
                className={s.mediaArticles__item}
              >
                <div className={s.mediaArticles__itemImg}>
                  <img
                    src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_02}
                    alt=''
                  />
                </div>
                <div className={s.mediaArticles__itemDesc}>
                  <div
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: articleTitle2 }}
                  ></div>
                  <div className={`${s.text} clamp`}>{articleText2}</div>
                </div>
              </a>
              <a
                href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_03}
                target='_blank'
                className={s.mediaArticles__item}
              >
                <div className={s.mediaArticles__itemImg}>
                  <img
                    src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_03}
                    alt=''
                  />
                </div>
                <div className={s.mediaArticles__itemDesc}>
                  <div
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: articleTitle3 }}
                  ></div>
                  <div className={`${s.text} clamp`}>{articleText3}</div>
                </div>
              </a>
            </div>

            <div className={s.mediaIcons}>
              <div className={s.mediaIcons__item}>
                <SectionNum num='08' />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const ContactsSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)')

  const toSection = (sectionId) => {
    const section = document
      .querySelector(`#${sectionId}`)
      .getBoundingClientRect()

    if (section.top < 0) {
      window.scrollTo({
        top: window.scrollY + section.top,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: section.top,
        behavior: 'smooth',
      })
    }
  }

  if (isMobile) {
    return (
      <section className={s.contacts} id='contacts'>
        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              Контакты
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={ContactsStrokeTextMobile}
                  src2x={ContactsStrokeTextMobile2x}
                  srcWebp={ContactsStrokeTextMobileWebp}
                />
              </div>
            </div>
          </div>

          <div className={s.contactsBody}>
            <Button
              onClick={() => toSection('callus')}
              className={s.contactsBody__btn}
              title='заказать проект'
            />
            <Button
              className={s.contactsBody__btn}
              href={process.env.REACT_APP_BLOG_LINK}
              title='читать блог'
            />
            <div className={s.contactsBody__socials}>
              <a
                href={process.env.REACT_APP_BLOG_LINK}
                target='_blank'
                className={s.contactsBody__socialsItem}
              >
                <span></span>
                <span></span>
                <img src={TgSvg} alt='' />
              </a>
              <a
                href={process.env.REACT_APP_GIT_LINK}
                target='_blank'
                className={s.contactsBody__socialsItem}
              >
                <span></span>
                <span></span>
                <img src={GitSvg} alt='' />
              </a>
            </div>
            <a
              href='mailto:business@foxsys.pro'
              className={s.contactsBody__socialsMail}
            >
              business@foxsys.pro
            </a>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className={s.contacts} id='contacts'>
        <div className={s.videobg}>
          <video autoPlay muted loop src={ContactsBgVideo}></video>
          <span className={s._bottom}></span>
          <span className={s._top}></span>
        </div>

        <div className='container'>
          <div className={s.sectionTop}>
            <div className={s.sectionTop__title}>
              Контакты
              <div className={s.sectionTop__titleBg}>
                <ImgWebp
                  src={ContactsStrokeText}
                  src2x={ContactsStrokeText2x}
                  srcWebp={ContactsStrokeTextWebp}
                />
              </div>
            </div>

            <div className={s.sectionTop__content}>
              <div className={s.contactsBody}>
                <Button
                  className={s.contactsBody__btn}
                  href={process.env.REACT_APP_BLOG_LINK}
                  title='читать блог'
                />
                <div className={s.contactsBody__socials}>
                  <a
                    href={process.env.REACT_APP_TG_LINK}
                    target='_blank'
                    className={s.contactsBody__socialsItem}
                  >
                    <span></span>
                    <span></span>
                    <img src={TgSvg} alt='' />
                  </a>
                  <a
                    href={process.env.REACT_APP_GIT_LINK}
                    target='_blank'
                    className={s.contactsBody__socialsItem}
                  >
                    <span></span>
                    <span></span>
                    <img src={GitSvg} alt='' />
                  </a>
                </div>
                <a
                  href='mailto:business@foxsys.pro'
                  className={s.contactsBody__socialsMail}
                >
                  business@foxsys.pro
                </a>
              </div>

              <div className={s.sectionTop__contentLine}></div>
            </div>
          </div>

          <div className={s.contactsIcons}>
            <div className={s.contactsIcons__item}>
              <DiagonalSvg />
            </div>
            <div className={s.contactsIcons__item}>
              <CrossSvg />
            </div>
            <div className={s.contactsIcons__item}>
              <SectionNum num={'09'} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default App
