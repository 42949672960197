const VersionSvg = () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      y='50'
      width='10'
      height='2'
      transform='rotate(-90 0 50)'
      fill='white'
    />
    <rect
      x='10'
      y='50'
      width='10'
      height='2'
      transform='rotate(-180 10 50)'
      fill='white'
    />
    <rect
      x='50'
      y='50'
      width='10'
      height='2'
      transform='rotate(-180 50 50)'
      fill='white'
    />
    <rect
      x='50'
      y='40'
      width='10'
      height='2'
      transform='rotate(90 50 40)'
      fill='white'
    />
    <mask
      id='mask0_453_723'
      // style={mask-type:'alpha'}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='50'
      height='10'
    >
      <rect width='50' height='10' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_453_723)'>
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 33.7307 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 6.76782 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 60.6917 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 40.4709 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 13.5081 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 47.2112 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 20.2483 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 0.0256348 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 53.9514 -3.50195)'
        fill='white'
      />
      <rect
        width='2.76134'
        height='19.4833'
        transform='matrix(0.640184 0.768221 -0.640185 0.768221 26.9885 -3.50195)'
        fill='white'
      />
    </g>
    <path
      d='M16.7698 18L10.6698 32H8.10984L1.98984 18H4.80984L9.46984 28.8L14.1698 18H16.7698ZM27.9075 18V32H25.3075V20.18H22.3475V18H27.9075ZM32.2447 32.14C31.7914 32.14 31.4047 31.9867 31.0847 31.68C30.7647 31.36 30.6047 30.96 30.6047 30.48C30.6047 29.9867 30.758 29.5867 31.0647 29.28C31.3847 28.9733 31.778 28.82 32.2447 28.82C32.7114 28.82 33.098 28.9733 33.4047 29.28C33.7247 29.5867 33.8847 29.9867 33.8847 30.48C33.8847 30.96 33.7247 31.36 33.4047 31.68C33.0847 31.9867 32.698 32.14 32.2447 32.14ZM41.1731 32.2C40.0531 32.2 39.0531 31.92 38.1731 31.36C37.2931 30.7867 36.5998 29.96 36.0931 28.88C35.5865 27.7867 35.3331 26.4933 35.3331 25C35.3331 23.5067 35.5865 22.22 36.0931 21.14C36.5998 20.0467 37.2931 19.22 38.1731 18.66C39.0531 18.0867 40.0531 17.8 41.1731 17.8C42.2931 17.8 43.2931 18.0867 44.1731 18.66C45.0665 19.22 45.7665 20.0467 46.2731 21.14C46.7798 22.22 47.0331 23.5067 47.0331 25C47.0331 26.4933 46.7798 27.7867 46.2731 28.88C45.7665 29.96 45.0665 30.7867 44.1731 31.36C43.2931 31.92 42.2931 32.2 41.1731 32.2ZM41.1731 29.94C42.1731 29.94 42.9598 29.5267 43.5331 28.7C44.1198 27.8733 44.4131 26.64 44.4131 25C44.4131 23.36 44.1198 22.1267 43.5331 21.3C42.9598 20.4733 42.1731 20.06 41.1731 20.06C40.1865 20.06 39.3998 20.4733 38.8131 21.3C38.2398 22.1267 37.9531 23.36 37.9531 25C37.9531 26.64 38.2398 27.8733 38.8131 28.7C39.3998 29.5267 40.1865 29.94 41.1731 29.94Z'
      fill='white'
    />
  </svg>
)

export default VersionSvg
