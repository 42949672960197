const DiagonalSvg = () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_453_1331)'>
      <mask
        id='mask0_453_1331'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='50'
        height='50'
      >
        <rect width='50' height='50' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_453_1331)'>
        <rect
          x='98.5564'
          y='-8.00073'
          width='8'
          height='81.9713'
          transform='rotate(45 98.5564 -8.00073)'
          fill='white'
        />
        <rect
          x='4.58325'
          y='-8.00073'
          width='8'
          height='81.9713'
          transform='rotate(45 4.58325 -8.00073)'
          fill='white'
        />
        <rect
          x='35.908'
          y='-8.00073'
          width='8'
          height='81.9713'
          transform='rotate(45 35.908 -8.00073)'
          fill='white'
        />
        <rect
          x='67.2322'
          y='-8.00073'
          width='8'
          height='81.9713'
          transform='rotate(45 67.2322 -8.00073)'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_453_1331'>
        <rect width='50' height='50' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default DiagonalSvg
