const CrossSvg = () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='1' y='1' width='48' height='48' stroke='white' strokeWidth='2' />
    <path d='M2 2L48 48' stroke='white' strokeWidth='2' />
    <path d='M48 2L2 48' stroke='white' strokeWidth='2' />
  </svg>
)

export default CrossSvg
